import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import SortListDropdown from "../dropdowns/sortListDropdown";
import { PackListItemWithType } from "../templates/listItems";
import ShareModal from "../modals/shareModal";

const PacksList = () => {
  const data = useStaticQuery(
    graphql`
      query PacksList {
        newest: allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: { frontmatter: { categoryid: { eq: "packs" } } }) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                typeid
                type
                date(formatString: "D MMM YYYY")
                mcpedlid
              }
            }
          }
        }
        oldest: allMarkdownRemark(sort: { fields: [frontmatter___date], order: ASC }, filter: { frontmatter: { categoryid: { eq: "packs" } } }) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                typeid
                type
                date(formatString: "D MMM YYYY")
                mcpedlid
              }
            }
          }
        }
        popular: allMarkdownRemark(sort: { fields: [frontmatter___popularity], order: ASC }, filter: { frontmatter: { categoryid: { eq: "packs" } } }) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                typeid
                type
                date(formatString: "D MMM YYYY")
                popularity
                mcpedlid
              }
            }
          }
        }
      }
    `
  );

  const { popular, newest, oldest } = data;

  const windowSearch = typeof window !== `undefined` ? window.location.search : null;

  const queryParams = new URLSearchParams(windowSearch);
  const sort = queryParams.get("sort") === "popular" ? popular : queryParams.get("sort") === "oldest" ? oldest : newest;

  return (
    <div className="section--navigator__content">
      <SortListDropdown />

      {sort.edges.map(({ node }) => (
        <PackListItemWithType mcpedlid={node.frontmatter.mcpedlid} title={node.frontmatter.title} date={node.frontmatter.date} slug={node.fields.slug} type={node.frontmatter.type} typeid={node.frontmatter.typeid} />
      ))}

      <ShareModal />
    </div>
  );
};

export default PacksList;
